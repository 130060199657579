// Note: these flag strings should be camelCased, even though in the
//       LaunchDarkly UI and in python they are kebab-cased.

export const ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT =
  'allowGroupPracticeToConfigureOwnBillingAccount';
export const ALWAYS_USE_GP_BILLING_ACCOUNT =
  'alwaysUseGroupPracticeBillingAccount';
export const PATIENT_COLLECTIONS_APPOINTMENT_MENU_PARTIALLY_PAID_BADGE =
  'patientCollectionsAppointmentMenuPartiallyPaidBadge';
export const PHI_GLASS_BREAK = 'updatedAuthzFlow';
export const APPOINTMENT_INVOICE_EVENTS = 'showInvoiceEventsInAtlas';
export const USE_ZENDESK_OUTAGE_BANNER = 'useZendeskOutageBanner';
export const TEMP_DISABLE_CONTACT_FORM = 'tempDisableContactForm'; // string that is the banner text
export const FORETHOUGHT_LIVE_CHAT_ENABLED = 'forethoughtLivechatEnabled';
export const LIVE_CHAT_INSURANCE_VERIFICATION =
  'insuranceVerificationViaLivechat';
export const SIGMUND_EMERGENCY_CONTACTS = 'sigmundEmergencyContacts';
export const PROVIDER_PAYMENT_TOOL_WITH_NEW_FIELDS =
  'providerPaymentToolWithNewFields';

export const SITE_MESSAGING_OPPS = 'siteMessagingOpps';
export const ALLOW_IMPERSONATOR_TICKET_SUBMISSION =
  'allowImpersonatorTicketSubmission';

export const SHOW_MESSAGING_UI_FILTERING_BAR = 'showMessageThreadFilteringBar';
export const OPTIMIZED_MESSAGES_VIEW = 'optimizedMessagesView';

export const SHOW_PROVIDER_MESSAGING_UI_BADGE_DOT =
  'showProviderMessagingBadgeDot';

export const ALLOW_ADJUST_PROVIDER_PAYMENT_POST_PAYOUT =
  'allowAdjustProviderPaymentPostPayout';

export const MULTI_STATE_CREDENTIALING_BETA = 'multiStateCredentialingBeta';
export const MULTI_STATE_CREDENTIALING_ENTRY_POINTS = 'mscEntryPoints';
export const MULTI_STATE_CREDENTIALING_ONBOARDING = 'mscOnboardingAccess';
export const MULTI_STATE_CREDENTIALING_GUARDRAIL_ROLLOUT =
  'multiStateCredentialingGuardrailRollout';
export const ENABLE_COI_DROPZONE_INSURANCE_STATUS_PAGE = `enableCoiDropzoneInsuranceStatusPage`;
export const MULTI_STATE_CREDENTIALING_MODIFY_PROVIDERS =
  'multiStateCredentialingModifyProviders';
export const MULTI_STATE_CREDENTIALING_AGORA = 'multiStateCredentialingAgora';
export const MULTI_STATE_CREDENTIALING_SEARCH = 'multiStateCredentialingSearch';
export const HIDE_IS_INSURANCE_ACTIVE_OVERRIDE =
  'hideIsInsuranceActiveOverride';
export const FRONT_END_CARRIERS_EXCLUSION_LIST =
  'frontEndCarriersExclusionList';
export const ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION =
  'anthemPatientFacingCarrierConsolidation';

export const HIDE_COMM_PATIENT_OVERRIDE = 'hideCommPatientOverride';

export const AUXILIARY_CONTACT_FORM_BANNER = 'auxiliaryContactFormBanner';

export const TELEHEALTH_LOCATIONS = 'telehealthLocations';

export const TELEHEALTH_LOCATIONS_PROVIDER_STATES =
  'telehealthLocationsProviderStates';
export const NQF_OPT_IN = 'nqfOptIn';
export const SDOH = 'socialDeterminantsOfHealth';
export const PROMS_UPSELLS = 'promsUpsells';
export const SUPPRESS_REQUIRED_PHQ_9_SCHEDULES =
  'suppressRequiredPhq9Schedules';

export const PATIENTS_TO_HOLD_ON_AUTOMATED_CHARGING =
  'patientsToHoldOnAutomatedCharging';

export const EXTOLE_PHASE_2_REFERRAL_PROGRAM = 'extolePhase2ReferralProgram';
export const EXTOLE_REFERRAL_TRACKER = 'extoleReferralTracker';

export const CONSOLIDATE_CREATE_USER_INSURANCE_NETWORK_CALLS =
  'consolidateCreateUserInsuranceNetworkCalls';
export const ACTIVE_CLAIM_FILING_CLEARINGHOUSES_FLAG =
  'activeClaimFilingClearinghouses';

export const PROGRESS_NOTES_SIGNATURE_RESTRICTIONS =
  'progressNotesSignatureRestrictions';
export const PROGRESS_NOTES_LATE_ENTRY = 'progressNotesLateEntry';

export const LICENSE_UPLOAD_DISABLED = 'licenseUploadDisabled';

export const MM_TREATMENT_PLAN = 'mmTreatmentPlanRequirement';

export const EMAIL_HISTORY_VIEWER = 'emailHistoryViewer';

export const TEXT_TO_SEARCH_FILTERS_LLM = 'textToSearchFiltersLlm';

// this group started as a hard exclusion group, but later we launched them
// when national autopay launched
export const LAVENDER_AND_OTHER_AUTOPAY_SPECIAL_GROUPS =
  'excludeFromRequiredAutopay';

export const DEA_INTAKE_FORM_UPDATES = 'deaIntakeFormUpdates';

export const USE_PATIENT_REMATCHING_FOR_PROVIDER_CANCELLATIONS =
  'usePatientRematchingForProviderCancellations';

export const MAX_SESSIONS_OVERRIDE = 'maxSessionsOverride';

export const PROVIDER_REFERRAL_PROGRAM_AWARENESS =
  'providerReferralProgramAwareness';

export const REACT_QUERY_STALE_TIME = 'reactQueryStaleTime';

export const PPR_FRONTEND_CARRIERS = 'pprCarriers';
export const PROGRESS_NOTES_REQUIREMENTS_REFACTOR =
  'progressNotesFormRequirementsRefactor';

export const STEP_DOWN_MODAL_EXPERIMENT_CALIFORNIA = 'stepDownModalExperiment';
export const STEP_DOWN_MODAL_EXPERIMENT_PENNSYLVANIA =
  'stepDownModalExperimentPa';
export const STEP_UP_MODAL_EXPERIMENT_NEW_YORK = 'stepUpModalExperimentNy';
export const ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP = 'acceptAnthemBankOfAmericaEap';

export const ENABLE_DESKTOP_OCR_INSURANCE_CHECKOUT_FLOW =
  'enableDesktopOcrInsuranceCheckoutFlow';
export const ENABLE_MOBILE_OCR_INSURANCE_CHECKOUT_FLOW =
  'enableMobileOcrInsuranceCheckoutFlow';

export const PROVIDER_DOCUMENT_AUDIT_RESULTS = 'providerDocumentAuditResults';
export const PATIENT_EXPERIMENTATION_TEST = 'patientExperimentationTest';

export const CONTROLLED_SUBSTANCE_DATA_COLLECTION =
  'controlledSubstanceDataCollection';

export const PRESCRIBER_PSYCHOTHERAPY_TIMES = 'prescriberPsychotherapyTimes';
export const PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS =
  'prescriberPsychotherapyTimesCarriers';
export const SHOW_CARE_NAVIGATION_POPUP = 'showCareNavigationPopup';
export const STEP_DOWN_CARE_PILOT_EXPERIENCE = 'stepDownCarePilotExperience';

export const PROMS_ONLY_RATE_BOOST = 'promsOnlyRateBoost';

export const USE_AUTH0_IN_PATIENT = 'useAuth0InPatient';

export const PASSWORD_COLLECTION_PROVIDER_TASKS =
  'passwordCollectionProviderTasks';
export const CAQH_PASSWORDS_USE_NEW_TABLE = 'caqhPasswordsUseNewTable';

export const SHOW_SAUTEES_EXPERIENCE = 'showSauteesExperience';
export const SSPLAT_UPDATE_FLOW = 'ssplatUpdateFlow';

export const USE_H2_2024_ISSUE_TYPE_TAXONOMY = 'useH22024IssueTypeTaxonomy';
export const SESSION_CONFIRMATION_FLOW_REVAMP = 'sessionConfirmationFlowRevamp';
export const SHOW_NEW_CARRIERS_STATES_EXPERIENCE =
  'showNewCarriersStatesExperience';

export const ENABLE_NEW_GET_CONFIRMABLE_SESSIONS =
  'enableNewGetConfirmableSessions';

export const PS_CVR_ONBOARDING = 'psCvrOnboarding';

export const AGORA_PATIENT_CHECKOUT_REDESIGN = 'agoraPatientCheckoutRedesign';

export const PRACTICE_DASHBOARD = 'practiceDashboard';
export const BULK_SESSION_CONFIRMATION = 'bulkSessionConfirmation';

export const PRICE_DISPUTE_2024 = 'priceDispute2024';

export const ENABLE_AETNA_IFP_WARNING = 'enableAetnaIfpWarning';

export const ENABLE_SUPERVISING_PHYSICIANS_JSON =
  'enableSupervisingPhysiciansJson';

export const ENABLE_ATLAS_SUPERVISING_PHYSICIANS =
  'enableAtlasSupervisingPhysicians';

export const IRONCLAD_BLOCK_APPOINTMENT_CONFIRMATION =
  'ironcladBlockAppointmentConfirmation';

export const DISABLE_INELIGIBLE_ISSUE_TYPE_CONTACT_FORM_FOR_INN_SPROUT_2024 =
  'disableIneligibleIssueTypeContactFormForInnSprout2024';

export const EXTERNAL_PLATFORM_NEAR_TERM_BOOKINGS =
  'externalPlatformNearTermBookings';

export const CALENDAR_PATIENTS_PAGINATED_REFACTOR =
  'calendarPatientsPaginatedRefactor';

export const CALENDAR_MAKE_PATIENTS_PAGINATED_NON_BLOCKING =
  'calendarMakePatientsPaginatedNonBlocking';

export const MAKE_CALENDAR_UPDATE_EVENT_NON_BLOCKING =
  'makeCalendarUpdateEventNonBlocking';

export const USE_GENERIC_INSURANCE_FORM_IN_APPOINTMENT_DETAIL =
  'useGenericInsuranceFormInAppointmentDetail';

export const REPLACE_COMPLETE_INSURANCE_FORM_WITH_GENERIC_FORM =
  'replaceCompleteInsuranceFormWithGenericForm';

export const MEDICAID_PLAN_DETECTION = 'medicaidPlanDetection';

export const NEW_CONSENT_FORMS_LAUNCH_DATE = 'newPatientConsentFormsLaunchDate';

export const NEW_SIGMUND_AUTH_STORE_FLOW = 'newSigmundAuthStoreFlow';

export const MAKE_CALENDAR_APPOINTMENT_DETAIL_NON_BLOCKING =
  'makeCalendarAppointmentDetailNonBlocking';

export const DISABLE_NEW_STRIPE_TRANSACTION_TOOL =
  'disableNewStripeTransactionTool';
